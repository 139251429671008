import React, {Component} from 'react';
import {
    Row,
    Col,
    Button
  } from "reactstrap";
import { Facebook, Instagram, Youtube } from "react-feather";

class Contact extends Component {
    render() {
        return (
            <Row className="logoMargin">
                
            </Row>
        );
    }
}

export default Contact;
