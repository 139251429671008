import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Icon.Home size={20} />,
    navLink: "/"
  },
  {
    id: "create",
    title: "Create Tokens",
    type: "item",
    icon: <Icon.PlusSquare size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/create"
  },
  {
    id: "portfolio",
    title: "My Tokens",
    type: "item",
    icon: <Icon.Briefcase size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/portfolio"
  },
  {
    id: "marketplace",
    title: "Marketplace",
    type: "item",
    icon: <Icon.Camera size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/marketplace"
  }
]

export default navigationConfig
