import React, {Component} from 'react';
import {
    Row,
    Col
  } from "reactstrap"

class Gitlab extends Component {
    render() {
        return (
            <Row className="logoMargin">
                <Col md="12" sm="12">
                    <h1 className="title-wizard wizardCard">Visit the code</h1>
                </Col>
                <Col md="12" sm="12">
                    <p className="wizardCard">The code can be found at <a href="https://gitlab.com/enrique_saucedo/alfinterface">ALF Frontend</a>
                    </p>
                </Col>
            </Row>
        );
    }
}

export default Gitlab;
