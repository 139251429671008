import React, { Suspense, lazy } from "react"
import { Router, Switch, Route } from "react-router-dom"
import { history } from "./history"
import { connect } from "react-redux"
import Spinner from "./components/@vuexy/spinner/Loading-spinner"
import { ContextLayout } from "./utility/context/Layout"
import Gitlab from './components/Gitlab';
import Contact from './components/Contact';
import Portfolio from './components/Portfolio';
import Marketplace from "./components/Marketplace";

// Route-based code splitting
const vuexyWizard = lazy(() => import("./components/@vuexy/wizard/Wizard"))
const formik = lazy(() => import("./views/forms/formik/Formik"))
const error404 = lazy(() => import("./views/pages/misc/error/404"))

// Set Layout and Component Using App Route
const RouteConfig = ({ component: Component, fullLayout, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return (
        <ContextLayout.Consumer>
          {context => {
            let LayoutTag =
              fullLayout === true
                ? context.fullLayout
                : context.state.activeLayout === "horizontal"
                ? context.horizontalLayout
                : context.VerticalLayout
            return (
              <LayoutTag {...props} permission={props.user}>
                <Suspense fallback={<Spinner />}>
                  <Component {...props} />
                </Suspense>
              </LayoutTag>
            )
          }}
        </ContextLayout.Consumer>
      )
    }}
  />
)
const mapStateToProps = state => {
  return {
    user: state.auth.login.userRole
  }
}

const AppRoute = connect(mapStateToProps)(RouteConfig)

class AppRouter extends React.Component {
  render() {
    return (
      // Set the directory path if you are deploying in sub-folder
      <Router history={history}>
        <Switch>
          <AppRoute exact path="/" component={Contact} />
          <AppRoute exact path="/create" component={vuexyWizard} />
          <AppRoute path="/settings" component={formik} />{" "}
          <AppRoute path="/marketplace" component={Marketplace} />
          <AppRoute exact path="/portfolio" component={Portfolio}/>
          <AppRoute component={error404} fullLayout />
        </Switch>
      </Router>
    )
  }
}

export default AppRouter
