import React, {Component} from 'react';
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Button
  } from "reactstrap";
import { AppContext } from "../App";
import CardFooter from 'reactstrap/lib/CardFooter';
import { OrderSide } from 'opensea-js/lib/types';
import { OpenSeaPort, Network } from 'opensea-js';
import axios from 'axios';

class Portfolio extends Component {

    state = {
        tokens: [],
        fixedTokens: [
            {id: 1616781896122,permalink: 'https://opensea.io/assets/0x311df247c126db2be238f1c2033bb10a5aee0e45/1616781896122', name:'Honey Badger Don’t Care!', image_url: 'https://lh3.googleusercontent.com/3z2nSfflaEirof9SImrdzFJfr7BOLBfe4hQUpbG9e-Cr2cr-RB3hmo2sFDDBzwf_gRCMqLSub4P86VCkVnVFfFImOXWxUdWtTaQ09UI=s260'},
            {id: 1616783491395,permalink: 'https://opensea.io/assets/0x311df247c126db2be238f1c2033bb10a5aee0e45/1616783491395', name:'All For You', image_url: 'https://lh3.googleusercontent.com/YGJYHJRm0sZGh2Md_YPq7frTYPxK67XSUhfnArEf7CxLIUII6f5xl5jj1a9uEm9qui4y8n42qUbgSXepPjgj4i5N1pR-8vsOctAR=s260'},
            {id: 9999999999999,permalink: 'https://opensea.io/bundles/konstellation-raumfahrt-p5K', name:'Konstellation Raumfahrt', image_url: 'https://ipfs.infura.io/ipfs/QmdeDM5rnj9GN7zEQComqfbrvpxfL8LeTtmk53hSPPw7p6'},
        ],
        orders: []
    }
    
     async componentDidMount() {
        // eslint-disable-next-line no-unused-vars
        let apiUrl;

        if (this.props.web3ctx.networkName === 'Rinkeby Testnet') {
            apiUrl = 'https://rinkeby-api.opensea.io';
        } else if (this.props.web3ctx.networkName === 'Ethereum Mainnet') {
            apiUrl = 'https://api.opensea.io';
        }

        const provider = window.ethereum;
        const seaport = new OpenSeaPort(provider, {
            networkName: Network.Rinkeby
        });
        
        if (this.props.web3ctx.activeWallet !== '') {
            axios.get(apiUrl + '/api/v1/assets?owner='+ this.props.web3ctx.activeWallet + '&order_direction=desc')
            .then(res => {
                const tokens = res.data.assets;
                this.setState({ tokens });
            })
        }
    }

    getRandomColor(id) {
        const colors = ["3bffd3", "ffa2c0", "f8eec0"];
        var seeds = Math.floor( (id/100) * Math.random() * colors.length);
        var index;

        if (seeds % 5 > 2) {
            index = 2;
        } else {
            index = seeds % 5;
        }

        var border = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='9' ry='9' stroke='%23`+ colors[index] +`FF' stroke-width='17' stroke-dasharray='14%2c 44%2c 8' stroke-dashoffset='` + Math.random() * (20 + id) +`' stroke-linecap='square'/%3e%3c/svg%3e")`;

        return border;
    }

    async sell(token, accountAddress) {
        console.log(token);
        let tokenId = token.token_id;
        let tokenAddress = token.asset_contract.address;
        
        const provider = window.ethereum;
        const seaport = new OpenSeaPort(provider, {
            networkName: Network.Rinkeby
        });

        const expirationTime = Math.round(Date.now() / 1000 + 60 * 60 * 24)

        const listing = await seaport.createSellOrder({
        asset: {
            tokenId,
            tokenAddress,
        },
        accountAddress,
        startAmount: 0.01,
        expirationTime
        });

        // const order = await seaport.api.getOrder({ 
        //     side: OrderSide.Sell,
        //     token_id: tokenId,
        //     asset_contract_address: tokenAddress
        // });

        // // eslint-disable-next-line no-unused-vars
        // const transactionHash = await seaport.fulfillOrder({ order, accountAddress });
    }

    render() {
        return (
            <AppContext.Consumer>
                {({web3ctx, myToken, actions}) => (
                    <>
                        <Row>
                            { this.state.tokens.map(token =>
                                <Col key={token.id} md="3" sm="3">
                                    <Card style={{backgroundImage: this.getRandomColor(token.id) }}>
                                        <a href={token.permalink}>
                                            <CardHeader>
                                                <CardTitle>
                                                    {token.name && token.name !== '' &&
                                                        <>
                                                        {token.name.length > 8 ? token.name.substr(0,15) + ' ...' : token.name}</>
                                                    }     
                                                </CardTitle>
                                            </CardHeader>
                                            <CardBody style={{textAlign: 'center'}}>
                                                <img 
                                                    src={token.image_url} 
                                                    alt={token.description} 
                                                    width='100%' 
                                                    style={{
                                                        borderRadius: '100%',
                                                        width: '150px',
                                                        height: '150px',
                                                    }}
                                                />
                                            </CardBody>
                                        </a>
                                        <CardFooter style={{textAlign:'center', color: '#000'}}>
                                            <Row>
                                                <Col md="12" sm="12">
                                                    <span>Token ID: {token.token_id.length > 8 ? token.token_id.substr(0,8) + ' ...' : token.token_id}</span>
                                                </Col>
                                            </Row>
                                            {/* <Row>
                                                <Col md="12" sm="12">
                                                    <Button onClick={() => {
                                                        this.sell(token, myToken.recipientAddress)
                                                    }}>Sell</Button>
                                                </Col>
                                            </Row> */}
                                        </CardFooter>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </>
                )}
            </AppContext.Consumer>
        );
    }
}

export default props => ( 
    <AppContext.Consumer>
        {({web3ctx, myToken}) => {
        return <Portfolio {...props} web3ctx={web3ctx} myToken={myToken} />
        }}
    </AppContext.Consumer>
);